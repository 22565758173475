<template>
  <div class="my-email">
    <div class="my-key">{{ title }}</div>
    <div class="my-value" :class="{'my-input-focus':mailOnfocus}">
      <input type="text" :placeholder="placeholder" v-model="mail"
             @blur="onMailBlur" @focus="onMailFocus" @change="onMailChange"/>
    </div>
    <div class="my-value-err-msg">{{ showMailErrMsg ? mailErrMsg : '' }}</div>
  </div>
</template>

<script>
import {regs} from "@/utils/validate"

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    validRule: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: true,
      default: 'example@email.com'
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      mail: '',
      mailOnfocus: false,
      showMailErrMsg: false,
      mailErrMsg: ''
    }
  },
  created() {
    console.log('InputText created:', this.title, this.validRule)
  },
  computed: {},
  methods: {
    validText() {
      const reg = this.validRule ? regs[this.validRule] : null
      if (reg === null) {
        return true
      }
      const mail = (this.mail || '').trim()
      if (mail === '') {
        this.mailErrMsg = `Required`
        this.showMailErrMsg = true
        return false
      }
      if (!reg.test(mail)) {
        this.mailErrMsg = `Please enter an email address in this format: example@email.com`
        this.showMailErrMsg = true
        return false
      }
      return true
    },
    onMailFocus() {
      console.log('onMailFocus')
      this.mailOnfocus = true
      this.showMailErrMsg = false
    },
    onMailBlur() {
      console.log('onMailBlur')
      this.mailOnfocus = false
      this.validText()
    },
    onMailChange(e) {
      this.setValue(e.target.value)
    },
    setValue(newVal) {
      const oldVal = this.mail
      this.mail = newVal
      console.log(`${this.title} mail:`, oldVal, '->', newVal)
      this.$emit('update:modelValue', this.mail)
    },
    watch: {
      // mail(newVal, oldVal) {
      //   console.log(`${this.title} mail:`, oldVal, '->', newVal)
      //   this.$emit('change', newVal)
      // }
    }
  }
}
</script>

<style lang="scss" scoped>

.my-key {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;

  //padding: 32px 0 8px 0;
  margin-bottom: 8px;
}

.my-value {
  width: 320px;
  height: 44px;

  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #D9DCE0;

  box-sizing: border-box;
  padding: 12px 8px;

  input {
    // 文本
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;

    width: 100%;
    border: none; // 不显示边框
    outline: none; // 不显示有焦点时的边框
    background: rgba(0, 0, 0, 0); // 背景完全透明
  }

  //input:focus {
  //  background: rgba(249, 120, 70, 0.1);
  //  opacity: 1;
  //}

  display: flex;
  flex-direction: row;
}

.my-input-focus {
  background: rgba(249, 120, 70, 0.1);
}

.my-value-err-msg {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #E42424;
  line-height: 20px;

  margin: 8px 0 8px 0;
}

.my-email {
}

</style>
