<template>
  <div class="my-pwd">
    <div class="my-key">{{ title }}</div>
    <div class="my-value" :class="{'my-input-focus':pwdOnfocus}">
      <!--
      <input type="text" placeholder="Password (6+ characters)" :value="encodePwd" @keyup="onPwdInput"
             @blur="onPwdBlur" @focus="onPwdFocus"/>
      -->
      <input type="text" :placeholder="placeholder" v-model="pwd" v-show="showRealPwd"
             @blur="onPwdBlur" @focus="onPwdFocus" ref="pwd"/>
      <input type="password" :placeholder="placeholder" v-model="pwd" v-show="!showRealPwd"
             @blur="onPwdBlur" @focus="onPwdFocus" ref="encodePwd"/>
      <div class="my-pwd-eye" @click="onClickEye">
        <component :is="showRealPwd?'icon-eye':'icon-eye-invisible'"
                   :style="{fontSize:'18px'}"></component>
      </div>
    </div>
    <div class="my-value-err-msg">{{ showPwdErrMsg ? pwdErrMsg : '' }}</div>
  </div>
</template>

<script>
import {regs} from '@/utils/validate'
import { IconEyeInvisible, IconEye } from '@arco-design/web-vue/es/icon';

export default {
  components: {
    IconEyeInvisible,
    IconEye,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true,
      default: 'Password (6+ characters)'
    }
  },
  emits: [ 'update:modelValue' ],
  data() {
    return {
      // rowPwd: '',
      // oldVal: '',
      pwd: '',
      pwdOnfocus: false,
      showPwdErrMsg: false,
      pwdErrMsg: 'Must be 6 or more characters',
      showRealPwd: false
    }
  },
  computed: {
    // encodePwd() {
    //   if (this.showRealPwd) return this.pwd
    //   return this.secretPwd(this.pwd)
    // }
    // rowPwd: {
    //   get() {
    //     console.log('rowPwd get', this.oldVal)
    //   },
    //   set(newVal) {
    //     console.log('rowPwd set', this.oldVal, '->', newVal)
    //     this.oldVal += newVal
    //   }
    // }
  },
  methods: {
    secretPwd(pwd) {
      let str = ''
      for (let i = 0; i < pwd.length; i++) str += '*'
      return str
    },
    showErrMsg(msg) {
      this.pwdErrMsg = msg
      this.showPwdErrMsg = true
    },
    validPwd() {
      const pwd = (this.pwd || '').trim()
      if (pwd === '') {
        this.pwdErrMsg = 'Required'
        this.showPwdErrMsg = true
        return false
      }
      if (!regs.pwd.test(pwd)) {
        this.pwdErrMsg = 'Must be 6 or more characters'
        this.showPwdErrMsg = true
        return false
      }
      return true
    },
    onPwdFocus() {
      console.log('onPwdFocus')
      this.pwdOnfocus = true
      this.showPwdErrMsg = false
    },
    onPwdBlur() {
      console.log('onPwdBlur')
      this.pwdOnfocus = false
      this.validPwd()
    },
    onPwdInput(e) {
      console.log('onPwdInput', e)
      if (e.key.length === 1) {
        this.pwd += e.key
      }
      if (e.key === 'Backspace') { // 删除
        this.pwd = this.pwd.substr(0, this.pwd.length - 1)
      }
    },
    onClickEye() {
      const show = this.showRealPwd = !this.showRealPwd
      if (show) {
        this.$refs.pwd.focus()
      } else {
        this.$refs.encodePwd.focus()
      }
    }
  },
  watch: {
    pwd(newVal, oldVal) {
      console.log(`${this.title} pwd:`, oldVal, '->', newVal)
      this.$emit('update:modelValue', newVal)
    }
    // rowPwd(newVal, oldVal) {
    //   console.log(`${this.title} rowPwd:`, oldVal, '->', newVal)
    //   // this.$emit('change', newVal)
    //   if (oldVal.length < newVal) {
    //     this.pwd = this.pwd.substr(0, oldVal)
    //   }
    //   this.rowPwd = this.secretPwd(newVal)
    // }
  }
}
</script>

<style lang="scss" scoped>

.my-key {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;

  //padding: 32px 0 8px 0;
  margin-bottom: 8px;
}

.my-value {
  width: 320px;
  height: 44px;

  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #D9DCE0;

  box-sizing: border-box;
  padding: 12px 8px;

  input {
    // 文本
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;

    width: 100%;
    border: none; // 不显示边框
    outline: none; // 不显示有焦点时的边框
    background: rgba(0, 0, 0, 0); // 背景完全透明
  }

  //input:focus {
  //  background: rgba(249, 120, 70, 0.1);
  //  opacity: 1;
  //}

  display: flex;
  flex-direction: row;
}

.my-input-focus {
  background: rgba(249, 120, 70, 0.1);
}

.my-value-err-msg {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #E42424;
  line-height: 20px;

  margin: 8px 0 8px 0;
}

.my-pwd {
  input {
    width: auto;
    flex-grow: 1;
  }
}

.my-pwd-eye {
  //position: absolute;
  //right: 0;
  //margin-left: -20px;
  //width: 20px;
  //box-sizing: content-box;
  //display: none;
  width: auto;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

</style>
