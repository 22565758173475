/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

export const regs = {
  email: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
  verifyCode: /^[0-9]{6}$/,
  pwd: /^\S{6,20}$/,
  companyName: /^\S{1,100}$/,
  address: /^\S{1,100}$/
}

function createValidator(valid, errorMsg) {
  return (rule, value, callback) => {
    try {
      if (valid(value)) {
        if (callback) callback()
        return true
      } else {
        if (callback) callback(new Error(errorMsg))
        return false
      }
    } catch (e) {
      if (callback) callback(e)
      return false
    }
  }
}

// 注册表单验证规则
export function createRegisterRules(_this) {
  return {
    email: [{
      required: true,
      trigger: 'blur',
      validator: createValidator(v => v && regs.email.test(v), "Email is incorrect!")
    }],
    verifyCode: [{
      required: true,
      trigger: 'blur',
      validator: createValidator(v => v && regs.verifyCode.test(v), "Verify Code is incorrect!")
    }],
    password: [{
      required: true,
      trigger: 'blur',
      validator: createValidator(v => v && regs.pwd.test(v), "The password must be 6 to 20 non blank characters!")
    }],
    confirmPassword: [{
      required: true,
      trigger: 'blur',
      validator: createValidator(v => v && v === _this.form.password, "The two passwords are inconsistent!")
    }],
    companyName: [{
      required: true,
      trigger: 'blur',
      validator: createValidator(v => v && regs.companyName.test(v), "The company name must be 1 to 100 non blank characters!")
    }],
    address: [{
      required: true,
      trigger: 'blur',
      validator: createValidator(v => v && regs.address.test(v), "The industry must be 1 to 100 non blank characters!")
    }],
    agreeProtocol: [{
      required: true,
      trigger: 'blur',
      validator: createValidator(v => v === true, "User protocol not agreed!")
    }]
  }
}
